exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presenters-js": () => import("./../../../src/pages/presenters.js" /* webpackChunkName: "component---src-pages-presenters-js" */),
  "component---src-pages-series-js": () => import("./../../../src/pages/series.js" /* webpackChunkName: "component---src-pages-series-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post-index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-presenter-js": () => import("./../../../src/templates/presenter.js" /* webpackChunkName: "component---src-templates-presenter-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

